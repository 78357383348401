<template>
	<div id="adoptEdit">
		<page-adopt-edit is-shop="0"></page-adopt-edit>
	</div>
</template>

<script>
	import pageAdoptEdit from '@/components/layout/adopt/page-adopt-edit.vue'
	export default{
		components:{
			pageAdoptEdit
		},
		data(){
			return{
			
			}
		},
		beforeRouteLeave (to, from, next) {
			to.meta.keepAlive = to.path == '/adopt/list' ? true :false
			next();
		},
	}
</script>

<style>
</style>
